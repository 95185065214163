import React, { useEffect } from 'react';
import "./StepOne.scss";
import "./TripBox.scss";
import Moment from 'moment';
import { IAmForAnalitics } from "./utilsApp";
import calendarImage from "./img/calendar.png";
import ReactHTMLParser from "react-html-parser"

class TripBox extends React.Component {
    render() {
        return (
            <div id="tripBox">
                <div>
                    <div className="imageContainer">
                        <img src={this.props.Reservation.Offer.ListImg?.url} alt={this.props.Reservation.Offer.ListImg?.alt} />
                    </div>
                    <div className="textBox">
                        <h4>{this.props.Reservation.Offer.Name}</h4>
                        <p className="date"><img src={calendarImage} alt="termin" />{Moment(this.props.Reservation.Offer.From).format('DD.MM') + "-" + Moment(this.props.Reservation.Offer.To).format('DD.MM.YYYY')}</p>
                        {this.props.accommodationOption === "whole" ? <p className="price" style={{ fontSize: "15px", marginBottom: "20px" }}>Cena jachtu pojawi się w kroku trzecim - po wyborze jednostki </p> : this.props.accommodationOption === "cabin" ? <p className="price" style={{ fontSize: "15px", marginBottom: "20px", textAlign: "left" }}>Cena za rezerwację kabiny pojawi się w kroku trzecim - po wyborze jednostki</p> : <><p className="price">{this.props.Reservation.Offer.CurrentPrice} PLN</p><p className="postscript">Łączna cena Twojego wyjazdu</p></>}
                        {this.props.Reservation.Offer.CurrentRebate !== 0 && this.props.accommodationOption === "single" ?
                            <>
                                {/* <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p> */}
                                <p className="rebate"><span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>W tym rabat, na który składa się:</span>
                                    {this.props.Reservation.Offer.RebatesList.map(el =>
                                        <p className="rebate">{el.Name}:
                            <span> {el.Value + " PLN"}</span></p>
                                    )}
                                </p>
                            </> : null}
                    </div>
                </div>
            </div>
        );
    }
}

const StepOne = (props) => {


    useEffect(() => {
        IAmForAnalitics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div id="stepOne" style={{ position: "relative" }}>
                {!props.buttonAvailableStepOne && <div id="stepMask"></div>}
                <div className="container">
                    <div className="selectRadio" id="reservationObject">
                        <h3>Co chcesz zarezerwować?</h3>
                        <form>
                            <div className="containerInputRadio">
                                {props.Reservation.Data[0].CanReservePlace && <label className="inputRadio">
                                    <p>Miejsce na wyjeździe</p>
                                    <p>Jedziesz sam(a) lub z małą grupą znajomych {props.Reservation.Offer.OfferId === "193" ? "" : "(nie potrzebujecie całego jachtu na wyłączność)"}</p>
                                    <input type="radio" name="accommodationOption" value="single" onChange={props.handleChange} checked={props.accommodationOption === "single"}></input>
                                    <span className="checkmark"></span>
                                </label>}
                                {props.Reservation.Offer.Name.includes("Kurs") || props.Reservation.Offer.Name.includes("Akademia") || props.Reservation.Offer.OfferId === "79" ? null :
                                    <>
                                        {props.Reservation.Data[0].CanReservePlace && !props.Reservation.Offer.Name.includes("Cup") && <label className="inputRadio">
                                            <p>Kajuta 2-osobowa (+300 PLN/os)</p>
                                            <p>Jest Was dwoje/dwóch/dwie i zależy Wam na zakwaterowaniu w zamykanej kabinie z podwójnym łóżkiem.</p>
                                            <input type="radio" name="accommodationOption" value="cabin" onChange={props.handleChange} checked={props.accommodationOption === "cabin"}></input>
                                            <span className="checkmark"></span>
                                        </label>}
                                        {props.Reservation.Data[0].CanReserveWhole &&
                                            <label className="inputRadio">
                                                <p>Cały jacht</p>
                                                <p>Jedziecie zgraną paczką i chcecie całą łódkę dla siebie na wyłączność.</p>
                                                <input type="radio" name="accommodationOption" value="whole" onChange={props.handleChange} checked={props.accommodationOption === "whole"}></input>
                                                <span className="checkmark"></span>
                                            </label>}

                                    </>
                                }
                            </div>
                        </form>
                    </div>
                    {props.accommodationOption === "whole" ?
                        props.Reservation.Offer.Name.includes("The Boat Trip") ?
                            <div class="greenBox" style={{ marginBottom: "50px", marginTop: "20px", backgroundColor: "rgba(255, 193, 7, 0.31)", fontWeight: "100" }}>
                                <p class="infoTitle" style={{ color: "orange", maxWidth: "100%" }}><span style={{ color: "orange", fontFamily: "SpaceGrotesk-SemiBold" }}>UWAGA: </span>
Na ten rejs dopusczamy rezerwacje całych jakchtów tylko z własnym sternikiem.</p>
                            </div>

                            :
                            props.Reservation.Offer.OfferId === "193" ? 
                            <div className="greenBox">
                                <p className='infoTitle'>Rezerwując cały jacht pamiętaj, że musisz mieć swojego skippera!
                                Jeżeli go nie masz to zrób rezerwację na pojedyncze miejsca, łącząc swoją ekipę kodem 
                                grupowym.</p>
                                </div> : 
                            <div id="amountPerson" className="counterContainer">
                                <h3>Dla ilu osób rezerwujesz wyjazd?</h3>
                                <div className="container" style={{ flexDirection: "row", minWidth: "auto", width: "550px", marginTop: "0" }}>
                                    <div>
                                        <>
                                            <p style={{ marginTop: "0" }}>Czy chcesz aby jachtem sterował skipper TBT, czy macie w ekipie swojego sternika?</p>
                                            <div className="containerInputRadio">
                                                <label className="inputRadio" ><p style={{ margin: "0" }}>Mamy swojego sternika i wpiszemy jego dane do rezerwacji.<span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}></span> </p><input type="radio" name="skipper" checked={props.skipper === "TAK"} value="TAK" onChange={props.handleChange}></input>
                                                    <span style={{ top: "1px" }} className="checkmark"></span>
                                                </label>
                                                <label className="inputRadio" style={{ marginTop: "10px" }}><p style={{ margin: "0" }}>Chcemy skippera od TBT (zajmie jedno miejsce na jachcie) {props.Reservation.Offer.OfferId === "188" ? "- wynajęcie skippera to koszt 700 EUR płatne na miejscu" : props.Reservation.Offer.OfferId === "248" ? "- wynajęcie skippera to koszt 800 PLN płatne na miejscu" : ""}<span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}></span> </p><input type="radio" name="skipper" checked={props.skipper === "NIE"} value="NIE" onChange={props.handleChange}></input>
                                                    <span style={{ top: "1px" }} className="checkmark"></span>
                                                </label>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        : props.accommodationOption === "single" ?
                            <div id="amountPerson" className="counterContainer">
                                <h3 style={{ marginBottom: "20px" }}>Dla ilu osób rezerwujesz wyjazd?</h3>
                                <div className="container">
                                    <div className="counterbox">
                                        <form>
                                            <div className="formCounter">
                                                <button className="primaryBtn less" onClick={props.handleClickLess}><p className="minus">-</p></button>
                                                <input type="text" disabled name="participantsCount" onChange={props.handleChange} value={props.participantsCount}></input>
                                                <button className="primaryBtn more" onClick={props.handleClickMore}><p>+</p></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> : null
                    }
                    {props.Reservation.Offer.ShowGroupRebate === true &&
                        props.userLogged && (props.accommodationOption === "single" || props.accommodationOption === "cabin") ?
                        <div id="connectionReservation" className="selectRadio" >
                            <h3>Łączenie rezerwacji (kody grupowe)</h3>
                            <div className="container">
                                <div className="textBox">
                                    <p>Jeśli jedziecie grupą znajomych i chcecie mieszkać razem, możecie zrobić
                                    jedną rezerwację wieloosobową lub kilka pojedynczych, które oznaczycie
                                    wspólnym kodem.
                                    {props.errorMessage2.includes("nieprawidłowy") ?
                                            props.render &&
                                            <>
                                                {props.buttonGenerateCodeVisible &&
                                                    <>
                                                        <span style={{ display: props.displayNone }}> Jeśli jesteś liderem grupy możesz wygenerować Wasz kod poniżej.</span>
                                                        <div className="boxButton"><button className="secondaryBtn"
                                                            style={{ cursor: "pointer", fontFamily: "SpaceGrotesk-SemiBold", display: props.displayNone }}
                                                            onClick={props.handleCreateGroupCode}>Wygeneruj kod grupowy</button></div>
                                                        <span style={{ display: props.displayNone }}></span>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {props.buttonGenerateCodeVisible &&
                                                    <>
                                                        <span style={{ display: props.displayNone }}> Jeśli jesteś liderem grupy możesz wygenerować Wasz kod poniżej.</span>
                                                        <div className="boxButton"><button className="secondaryBtn"
                                                            style={{ cursor: "pointer", fontFamily: "SpaceGrotesk-SemiBold", display: props.displayNone }}
                                                            onClick={props.handleCreateGroupCode}>Wygeneruj kod grupowy</button>
                                                        </div>
                                                        <span style={{ display: props.displayNone }}></span>
                                                    </>
                                                }
                                            </>
                                        }
                                    </p>
                                    <p className="confirmedInformation">{props.trueMessage}</p>
                                    <p className="errorMessage">{props.errorMessage}</p>


                                    {props.addGrupCodeVisible &&
                                        <>
                                            <p>Jeśli dołączasz do istniejącej grupy, wklej Wasz kod poniżej.</p>
                                            <div id="liderBox" className="inputBox">
                                                <p className="inputName">Podaj kod grupowy (opcjonalnie)</p>
                                                <div className="formContainer">
                                                    <input type="text" name="groupCode" value={props.groupCode} onChange={props.handleChange} /><button className="secondaryBtn" onClick={props.handleAddGroupCode}>Dołącz</button>
                                                </div>

                                                <p className="errorMessage">{ReactHTMLParser(props.errorMessage2)}</p>
                                            </div>
                                        </>
                                    }

                                    {props.trueMessageVisible && <p className="confirmedInformation">{props.secondTrueMessage}</p>}

                                </div>
                                <div className="greenBox">
                                    <p className="infoTitle">Cieszymy się, że jest Was tak dużo!</p>
                                    <p style={{ marginTop: 0, fontFamily: 'SpaceGrotesk-SemiBold', color: "#3db74c" }}>Kody grupowe zapewniają</p>
                                    <p>1. Zniżki grupowe (dla 6 i więcej osób)</p>
                                    <p>2. Wspólne zakwaterowanie (dla osób, które wybiorą ten sam standard)</p>
                                    <p>3. Jedziecie razem, ale każdy płaci za siebie</p>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    {props.Reservation.Offer.OfferId === "82" &&
                        <div id="amountPerson" className="counterContainer">
                            <h3>Czy jesteś skipperem TBT?</h3>
                            <div className="container" style={{ flexDirection: "row", minWidth: "auto", marginTop: "0", width: "fit-content"}}>
                                <div>
                                            <div className="containerInputRadio">
                                                <label className="inputRadio" ><p style={{ margin: "0", fontFamily: "SpaceGrotesk-SemiBold" }}>Tak <span>(zniżka -400 PLN zostanie naliczona w szóstym kroku rezerwacji)</span> </p><input type="radio" name="beSkipper" checked={props.beSkipper === "TAK"} value="TAK" onChange={props.handleChange}></input>
                                                    <span style={{ top: "1px" }} className="checkmark"></span>
                                                </label>
                                                <label className="inputRadio" style={{ marginTop: "10px" }}><p style={{ margin: "0" }}>Nie<span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}></span> </p><input type="radio" name="beSkipper" checked={props.beSkipper === "NIE"} value="NIE" onChange={props.handleChange}></input>
                                                    <span style={{ top: "1px" }} className="checkmark"></span>
                                                </label>
                                            </div>
                                </div>
                            </div>
                        </div>
                    }
                    <p className="errorMessage">{props.messageStepOne}</p>
                    <div className="containerButtons">
                        <button className="primaryBtn" onClick={props.handleUpdateStepOne} disabled={props.buttonAvailableStepOne ? "" : "disabled"} id={props.buttonAvailableStepOne ? null : "disabledButton"} >Dalej</button>
                        {!props.buttonAvailableStepOne && <div class="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        }
                    </div>
                </div>
                <TripBox Reservation={props.Reservation} accommodationOption={props.accommodationOption} />
            </div>
        </>
    );
}

export default StepOne;